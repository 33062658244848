import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {HolidayDto} from "../../models/holiday.model";

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  apiUrl = `${environment.myBackend}/hr/org/holiday`;

  constructor(private http: HttpClient) {

  }

  addHoliday(value: HolidayDto): Observable<HolidayDto> {
    return this.http.put<HolidayDto>(this.apiUrl, value);
  }

  getHoliday(filters: any): Observable<HolidayDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    return this.http.get<HolidayDto[]>(`${this.apiUrl}?${qs}`);
  }

  updateHoliday(id: number, value: updateHolidayReqDto): Observable<HolidayDto> {
    return this.http.patch<HolidayDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteHoliday(id: number): Observable<any> {
    return this.http.delete<HolidayDto>(`${this.apiUrl}/${id}`);
  }
}

export type updateHolidayReqDto = {
  name?: string;
  username?:string;
  password?:string;

}
