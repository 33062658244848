import {createReducer, on} from '@ngrx/store';
import * as HolidayActions from '../actions/holiday.actions';
import {HolidayDto} from "../models/holiday.model";


export interface HolidayState {
  holidays: HolidayDto[];
  error: any;
}

export const initialState: HolidayState = {
  holidays: [],
  error: null,
};

export const holidayReducer = createReducer(
  initialState,
  on(HolidayActions.loadHolidaysSuccess, (state, { holidays }) => ({ ...state, holidays:[...holidays] })),
  on(HolidayActions.loadHolidaysFailure, (state, { error }) => ({ ...state, error })),
  on(HolidayActions.addHolidaySuccess, (state, { holiday }) => ({ ...state, holidays: [...state.holidays, holiday] })),
  on(HolidayActions.addHolidayFailure, (state, { error }) => ({ ...state, error })),
  on(HolidayActions.updateHolidaySuccess, (state, { holiday }) => ({
    ...state,
    holidays: state.holidays.map(u => (u.id === holiday.id ? holiday : u))
  })),
  on(HolidayActions.updateHolidayFailure, (state, { error }) => ({ ...state, error })),
  on(HolidayActions.deleteHolidaySuccess, (state, { id }) => ({
    ...state,
    holidays: state.holidays.filter(holiday => holiday.id !== id)
  })),
  on(HolidayActions.deleteHolidayFailure, (state, { error }) => ({ ...state, error }))
);
