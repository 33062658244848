import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LeaveService} from '../services/leave.service';
import * as LeaveActions from '../actions/leave.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class LeaveEffects {
  constructor(private actions$: Actions, private leaveService: LeaveService) {
  }

  loadKRACycles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.loadMyLeaves),
      mergeMap(action =>
        this.leaveService.getMyLeaves(action).pipe(
          map(myLeaves => LeaveActions.loadMyLeavesSuccess({myLeaves})),
          catchError(error => of(LeaveActions.loadMyLeavesFailure({error})))
        )
      )
    )
  );

  addKRACycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.applyMyLeaves),
      mergeMap(action =>
        this.leaveService.applyMyLeave(action.myLeave).pipe(
          map(myLeave => LeaveActions.applyMyLeavesSuccess({myLeave})),
          catchError(error => of(LeaveActions.applyMyLeavesFailure({error})))
        )
      )
    )
  );

  updateKRACycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.updateMyLeaves),
      mergeMap(action =>
        this.leaveService.updateMyLeave(action.id, action.myLeave).pipe(
          map(myLeave => LeaveActions.updateMyLeavesSuccess({myLeave})),
          catchError(error => of(LeaveActions.updateMyLeavesFailure({error})))
        )
      )
    )
  );

  deleteKRACycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.deleteMyLeaves),
      mergeMap(action =>
        this.leaveService.deleteMyLeave(action.id).pipe(
          map(() => LeaveActions.deleteMyLeavesSuccess({id: action.id})),
          catchError(error => of(LeaveActions.deleteMyLeavesFailure({error})))
        )
      )
    )
  );


  loadManagerCycles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.loadManagerLeaves),
      mergeMap(action =>
        this.leaveService.getManagerLeaves(action).pipe(
          map(managerLeaves => LeaveActions.loadManagerLeavesSuccess({managerLeaves})),
          catchError(error => of(LeaveActions.loadManagerLeavesFailure({error})))
        )
      )
    )
  );


  actionManagerCycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.actionManagerLeaves),
      mergeMap(action =>
        this.leaveService.actionManagerLeave(action.id, action.action,action.actionReason).pipe(
          map(managerLeave => LeaveActions.actionManagerLeavesSuccess({managerLeave: managerLeave})),
          catchError(error => of(LeaveActions.actionManagerLeavesFailure({error})))
        )
      )
    )
  );


  loadHRCycles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.loadHRLeaves),
      mergeMap(action =>
        this.leaveService.getHRLeaves(action).pipe(
          map(HRLeaves => LeaveActions.loadHRLeavesSuccess({HRLeaves})),
          catchError(error => of(LeaveActions.loadHRLeavesFailure({error})))
        )
      )
    )
  );

  addHRCycle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.applyHRLeaves),
      mergeMap(action =>
        this.leaveService.applyHRLeave(action.HRLeave).pipe(
          map(HRLeave => LeaveActions.applyHRLeavesSuccess({HRLeave})),
          catchError(error => of(LeaveActions.applyHRLeavesFailure({error})))
        )
      )
    )
  );

  loadLeaves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeaveActions.loadLeaves),
      mergeMap(action =>
        this.leaveService.getLeaves(action.filter).pipe(
          map(leaves => LeaveActions.loadLeavesSuccess({leaves})),
          catchError(error => of(LeaveActions.loadLeavesFailure({error})))
        )
      )
    )
  );


  // updateHRCycle$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LeaveActions.updateHRLeaves),
  //     mergeMap(action =>
  //       this.leaveService.updateManagerLeave(action.id, action.HRLeave).pipe(
  //         map(HRLeave => LeaveActions.updateHRLeavesSuccess({ HRLeave })),
  //         catchError(error => of(LeaveActions.updateHRLeavesFailure({ error })))
  //       )
  //     )
  //   )
  // );
  //
  // deleteHRCycle$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LeaveActions.deleteHRLeaves),
  //     mergeMap(action =>
  //       this.leaveService.deleteManagerLeave(action.id).pipe(
  //         map(() => LeaveActions.deleteHRLeavesSuccess({ id: action.id })),
  //         catchError(error => of(LeaveActions.deleteHRLeavesFailure({ error })))
  //       )
  //     )
  //   )
  // );


}
