import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HolidayService} from '../services/holiday/holiday.service';
import * as HolidayActions from '../actions/holiday.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class HolidayEffects {
  constructor(private actions$: Actions, private holidayService: HolidayService) {}

  loadHoliday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.loadHolidays),
      mergeMap(action =>
        this.holidayService.getHoliday(action).pipe(
          map(holidays => HolidayActions.loadHolidaysSuccess({ holidays })),
          catchError(error => of(HolidayActions.loadHolidaysFailure({ error })))
        )
      )
    )
  );

  addHoliday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.addHoliday),
      mergeMap(action =>
        this.holidayService.addHoliday(action.holiday).pipe(
          map(holiday => HolidayActions.addHolidaySuccess({ holiday })),
          catchError(error => of(HolidayActions.addHolidayFailure({ error })))
        )
      )
    )
  );

  updateHoliday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.updateHoliday),
      mergeMap(action =>
        this.holidayService.updateHoliday(action.id, action.holiday).pipe(
          map(holiday => HolidayActions.updateHolidaySuccess({ holiday })),
          catchError(error => of(HolidayActions.updateHolidayFailure({ error })))
        )
      )
    )
  );

  deleteHoliday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.deleteHoliday),
      mergeMap(action =>
        this.holidayService.deleteHoliday(action.id).pipe(
          map(() => HolidayActions.deleteHolidaySuccess({ id: action.id })),
          catchError(error => of(HolidayActions.deleteHolidayFailure({ error })))
        )
      )
    )
  );
}
