import {createReducer, on} from '@ngrx/store';
import * as LeaveActions from '../actions/leave.actions';
import {LeaveDto} from "../models/leave.model";

export interface LeaveState {
  HRLeaves: LeaveDto[];
  managerLeaves: LeaveDto[];
  myLeaves: LeaveDto[]
  leaves: LeaveDto[]
  error: any;
}

export const initialState: LeaveState = {
  HRLeaves: [],
  managerLeaves: [],
  myLeaves: [],
  leaves:[],
  error: null,
};

export const leaveReducer = createReducer(
  initialState,
  on(LeaveActions.loadMyLeavesSuccess, (state, {myLeaves}) => ({...state, myLeaves: [...myLeaves]})),
  on(LeaveActions.loadMyLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.applyMyLeavesSuccess, (state, {myLeave}) => ({...state, myLeaves: [...state.myLeaves, myLeave]})),
  on(LeaveActions.applyMyLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.updateMyLeavesSuccess, (state, {myLeave}) => ({
    ...state,
    myLeaves: state.myLeaves.map(u => (u.id === myLeave.id ? myLeave : u))
  })),
  on(LeaveActions.updateMyLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.deleteMyLeavesSuccess, (state, {id}) => ({
    ...state,
    myLeaves: state.myLeaves.filter(myLeave => myLeave.id !== id)
  })),
  on(LeaveActions.deleteMyLeavesFailure, (state, {error}) => ({...state, error})),


  on(LeaveActions.loadManagerLeavesSuccess, (state, {managerLeaves}) => ({...state, managerLeaves: [...managerLeaves]})),
  on(LeaveActions.loadManagerLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.actionManagerLeavesSuccess, (state, {managerLeave}) => ({
    ...state,
    managerLeaves: state.managerLeaves.map(u => (u.id === managerLeave.id ? managerLeave : u))
  })),
  on(LeaveActions.actionManagerLeavesFailure, (state, {error}) => ({...state, error})),


  on(LeaveActions.loadHRLeavesSuccess, (state, {HRLeaves}) => ({...state, HRLeaves: [...HRLeaves]})),
  on(LeaveActions.loadHRLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.applyHRLeavesSuccess, (state, {HRLeave}) => ({...state, HRLeaves: [...state.HRLeaves, HRLeave]})),
  on(LeaveActions.applyHRLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.updateHRLeavesSuccess, (state, {HRLeave}) => ({
    ...state,
    HRLeaves: state.HRLeaves.map(u => (u.id === HRLeave.id ? HRLeave : u))
  })),
  on(LeaveActions.updateHRLeavesFailure, (state, {error}) => ({...state, error})),
  on(LeaveActions.deleteHRLeavesSuccess, (state, {id}) => ({
    ...state,
    HRLeaves: state.HRLeaves.filter(HRLeave => HRLeave.id !== id)
  })),
  on(LeaveActions.deleteHRLeavesFailure, (state, {error}) => ({...state, error})),

  on(LeaveActions.loadLeavesSuccess, (state, {leaves}) => ({...state, leaves: [...leaves]})),
  on(LeaveActions.loadLeavesFailure, (state, {error}) => ({...state, error})),

);
