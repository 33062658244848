import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LeaveDto} from "../models/leave.model";

@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  apiUrl = `${environment.myBackend}/hr/leave/leave`;

  constructor(private http: HttpClient) {

  }

  applyMyLeave(value: LeaveDto): Observable<LeaveDto> {
    return this.http.put<LeaveDto>(`${this.apiUrl}/applyMyLeave`, value);
  }

  getMyLeaves(filters: any): Observable<LeaveDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    return this.http.get<LeaveDto[]>(`${this.apiUrl}/MyLeave?${qs}`);

  }

  updateMyLeave(id: number, value: LeaveDto): Observable<LeaveDto> {
    return this.http.patch<LeaveDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteMyLeave(id: number): Observable<any> {
    return this.http.delete<LeaveDto>(`${this.apiUrl}/${id}`);
  }


  getManagerLeaves(filters: any): Observable<LeaveDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    return this.http.get<LeaveDto[]>(`${this.apiUrl}/ManagerLeave?${qs}`);

  }


  actionManagerLeave(id: number, action: String, actionReason: String): Observable<any> {
    return this.http.patch<LeaveDto>(`${this.apiUrl}/actionLeave`, {leaveID: id, action: action,actionReason:actionReason});
  }


  applyHRLeave(value: LeaveDto): Observable<LeaveDto> {
    return this.http.put<LeaveDto>(`${this.apiUrl}/applyHRLeave`, value);
  }

  getHRLeaves(filters: any): Observable<LeaveDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.nameFilter) {
      qs += "nameFilter=" + filters.nameFilter + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    return this.http.get<LeaveDto[]>(`${this.apiUrl}/getHRLeave?${qs}`);

  }

  updateHRLeave(id: number, value: LeaveDto): Observable<LeaveDto> {
    return this.http.patch<LeaveDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteHRLeave(id: number): Observable<any> {
    return this.http.delete<LeaveDto>(`${this.apiUrl}/${id}`);
  }

  getLeaves(filters: any): Observable<LeaveDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.type) {
      qs += "type=" + filters.type + "&";
    }
    if (filters && filters.usernameFilter) {
      qs += "usernameFilter=" + filters.usernameFilter + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    return this.http.get<LeaveDto[]>(`${this.apiUrl}?${qs}`);

  }

}
